import router from "next/router";
import axiosInstance from "./axios";
import { getHeaders } from "./apiHeaders";
import axios from "axios";
let headers = {};
let response = "";

let customDomainConfigs = {
  headers: {
    // Authorization: "Bearer EyV7TgjLzM7IW67BsnO8dO6nb8puOmvVfGQs4YmF",
    "Content-Type": "application/json",
    auth_access: getHeaders(),
  },
};

const siteService = {
  //create site data
  createSite: async function (payload) {
    try {
      let response = await axiosInstance.post("/api/site/create", payload);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //create/update site card
  submitSiteCard: async function (payload) {
    try {
      let response = await axiosInstance.post(
        "/api/site/create-update-card",
        payload
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //update site data
  updateSite: async function (payload) {
    try {
      let response = await axiosInstance.patch("/api/site/update", payload);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get site data
  getSiteData: async function (payload) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-site-data-by-sitename/${payload?.site_id}/${payload?.user_id}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  //get site data
  getSiteDataByName: async function (payload) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-site-data-sitename/${payload}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get site card data
  getSiteCardData: async function (payload) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-site-preview/${payload}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get site card list
  getSiteCardList: async function (payload, user_id, locale) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-site-cards/${payload}/${user_id}/${locale}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //delete cards
  deleteSiteCard: async function (payload) {
    try {
      let response = await axiosInstance.delete(
        `/api/site/card-delete/${payload}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //create account
  createOrder: async function (payload) {
    try {
      response = await axiosInstance.post("createOrder", payload);
      return response;
    } catch (error) {
      const { name, message } = error;
      // response = `${name} => ${message}`;
      response = error.response;
      return response;
    }
  },

  //get category, subcategory list
  getCategorySubcategoryList: async function (payload) {
    try {
      response = await axiosInstance.get(
        `/api/site/get-cards-by-category/${payload}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get user site list
  getUserSiteList: async function (payload) {
    try {
      response = await axiosInstance.get(
        `/api/site/get-my-site-list/${payload}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get ContactCard
  getContactCard: async function (payload) {
    try {
      response = await axiosInstance.get(
        `/api/site/get-contact-card-data-by-id/${payload}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get Contact Card Details By Site Id
  getContactCardDetailsBySiteId: async function (payload) {
    try {
      response = await axiosInstance.get(
        `/api/site/get-contact-card-data-by-site_id/${payload}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //get Contact Card delete
  getContactCardDelete: async function (payload) {
    try {
      response = await axiosInstance.delete(
        `/api/site/contact-card-delete/${payload}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //create ContactCard
  createUpdateContactCard: async function (payload) {
    try {
      response = await axiosInstance.post(
        `/api/site/create-update-contact-card`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //ge tSite Data By SiteName
  SiteUpdate: async function (payload) {
    try {
      response = await axiosInstance.patch(`api/site/update`, payload);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //create feedback on site-analytics
  createFeedback: async function (payload) {
    try {
      response = await axiosInstance.post(`api/site/create-feedback`, payload);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // card-position-update
  cardPositionUpdate: async function (payload) {
    try {
      response = await axiosInstance.patch(
        `api/site/card-position-update`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // create-update-customization-stylelooks
  createUpdateCustomizeStyle: async function (payload) {
    try {
      response = await axiosInstance.post(
        `api/site/create-update-customization-stylelooks`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  setCustomizeTheme: async function (payload) {
    try {
      response = await axiosInstance.patch(`api/site/update`, payload);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getCustomizeStyle: async function (payload) {
    try {
      response = await axiosInstance.get(
        `api/site/get-site-preview/${payload.site_id}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  //Get Current Theme list
  getCustomizeTheme: async function () {
    try {
      response = await axiosInstance.get(`api/site/get-theme`);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // Site-Verify
  siteVerify: async function (payload) {
    try {
      response = await axiosInstance.patch(`api/site/site-verify`, payload);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // list email subscriber
  listemailsubscriber: async function (payload) {
    try {
      response = await axiosInstance.post(
        `api/user/listemailsubscriber`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // getcardscategory
  getcardscategory: async function (payload) {
    try {
      response = await axiosInstance.get(
        `api/site/get-cards-category/${payload}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // addemailsubscriber
  addemailsubscriber: async function (payload) {
    try {
      response = await axiosInstance.post(
        `api/user/addemailsubscriber`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // getcustommessage
  getcustommessage: async function (payload) {
    try {
      response = await axiosInstance.post(`api/user/getcustommessage`, payload);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // subscribersettings
  subscribersettings: async function (payload) {
    try {
      response = await axiosInstance.post(
        `api/user/subscribersettings`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // verifyotp
  verifyotp: async function (payload) {
    try {
      response = await axiosInstance.post(`api/user/verifyotp`, payload);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // upload background image
  uploadBgImage: async function (payload) {
    try {
      let headers = {
        "Content-Type": "multipart/form-data",
        authorization: "",
      };
      return axiosInstance.patch(
        `/api/site/file-uploaded/stylizelook/`,
        payload,
        { headers }
      );
    } catch (error) {
      return error?.response?.data;
    }
  },
  // set background image url
  createBgImages: async function (payload) {
    try {
      response = await axiosInstance.post(
        `api/site/create-background-images`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  // get uploaded background image
  getBgImages: async function (payload) {
    try {
      response = await axiosInstance.post(
        `api/site/get-background-images`,
        payload
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  // /api/checkdomain name
  checkDomainNameExist: async function (payload) {
    try {
      // let dataSet = {
      //   site_id: 180,
      //   user_id: 390,
      //   domainName: domainNameArray[0],
      // };
      response = await axiosInstance.get(
        `api/site/get-site-data-by-domainname/${payload}`
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  // /api/addcustomdomaincf
  registerCustomDomain: async function (payload) {
    try {
      // let dataSet = {
      //   site_id: 180,
      //   user_id: 390,
      //   domainName: domainNameArray[0],
      // };
      console.log("customDomainConfigs ", customDomainConfigs);
      response = await axios.post(
        `/api/addcustomdomaincf`,
        payload,
        customDomainConfigs
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  getCNAMErecords: async function (payload) {
    try {
      // let configData = {
      //   // site_id: 180,
      //   // user_id: 390,
      //   arn_value: responseData?.data?.data,
      // };
      response = await axios.post(
        "/api/getcnamerecords",
        payload,
        customDomainConfigs
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  verifyDomainName: async function (payload) {
    try {
      response = await axios.post(
        "/api/checksslissued",
        payload,
        customDomainConfigs
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  deleteDomainName: async function (payload) {
    try {
      response = await axios.post(
        "/api/deletedomaincf",
        payload,
        customDomainConfigs
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },

  deleteDomainNameCert: async function (payload) {
    try {
      response = await axios.post(
        "/api/deletecertificate",
        payload,
        customDomainConfigs
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
};

export default siteService;
