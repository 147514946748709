import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import logger from "redux-logger";
import commonReducer from "./features/common/commonSlice";
import siteReducer from "./features/site/siteSlice";
import userReducer from "./features/user/userSlice";

const reducer = combineReducers({
  common: commonReducer,
  site: siteReducer,
  user: userReducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    // return getDefaultMiddleware().concat(logger);

    if (process.env.ENV_MODE !== "PRODUCTION") {
      return getDefaultMiddleware().concat(logger);
    } else {
      return getDefaultMiddleware();
    }
  },
  devTools: process.env.NODE_ENV !== "PRODUCTION",
});
export default store;
