import { createSlice, current } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/Statuses";
import userThunkAPI from "./middleware";

//auth utils
import { setCurrentUser, removeCurrentUser } from "@src/utils/auth";

// const userToken = localStorage.getItem("userToken")
// ? localStorage.getItem("userToken") : null

const initialState = {
  userDetails: null,
  userUpdate: null,
  userStripeUpdate: null,
  user: null,
  userLoginData: null,
  isLoggedIn: false,
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userSignOut: (state) => {
      state.user = null;
      state.userLoginData = null;
      // removeCommonAuthUserFromSweplyAuth();
      removeCurrentUser(); //remove from cookie
      // signOut(); //remove from cognito
    },
    setUser: (state, actions) => {
      state.userLoginData = actions?.payload;
      state.user = actions?.payload?.signInUserSession;
      if (actions?.payload?.signInUserSession?.accessToken?.jwtToken) {
        let t = {
          jwtToken: actions?.payload?.signInUserSession?.accessToken?.jwtToken,
        };
        localStorage.setItem("userToken", JSON.stringify(t));
        // setCurrentUser(t);
      }
    },
    setUserData: (state, actions) => {
      // state.userLoginData = actions?.payload;
      state.userDetails = actions?.payload;
    },
  },
  extraReducers: {
    // [userThunkAPI.signupAsync.pending]: (state, action) => {
    //   state.status = STATUSES.LOADING;
    // },
    // [userThunkAPI.signupAsync.fulfilled]: (state, action) => {
    //   state.user = action?.payload;
    //   state.status = STATUSES.IDLE;
    // },
    // [userThunkAPI.signupAsync.rejected]: (state, action) => {
    //   state.status = STATUSES.ERROR;
    // },

    //get user site list
    [userThunkAPI.pending]: (state, action) => {
      state.userDetails = STATUSES.LOADING;
    },

    [userThunkAPI.getUserDetailsAsync.fulfilled]: (state, action) => {
      state.userDetails = action?.payload?.data;
    },
    [userThunkAPI.getUserDetailsAsync.rejected]: (state, action) => {
      state.userDetails = STATUSES.ERROR;
    },

    //update user site list
    [userThunkAPI.updateUser.pending]: (state, action) => {
      state.userUpdate = STATUSES.LOADING;
    },

    [userThunkAPI.updateUser.fulfilled]: (state, action) => {
      state.userUpdate = action?.payload?.data;
    },
    [userThunkAPI.updateUser.rejected]: (state, action) => {
      state.userUpdate = STATUSES.ERROR;
    },

    //update user site list
    [userThunkAPI.updateUserStripe.pending]: (state, action) => {
      state.userStripeUpdate = STATUSES.LOADING;
    },

    [userThunkAPI.updateUserStripe.fulfilled]: (state, action) => {
      state.userStripeUpdate = action?.payload?.data;
    },
    [userThunkAPI.updateUserStripe.rejected]: (state, action) => {
      state.userStripeUpdate = STATUSES.ERROR;
    },
  },
});

export const { userSignOut, setUser, setUserData } = userSlice.actions;

export default userSlice.reducer;
