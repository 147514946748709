import { createAsyncThunk } from "@reduxjs/toolkit";
import userService from "@src/services/userService";

const userThunkAPI = {
  //create account
  getUserDetailsAsync: createAsyncThunk("users-details", async (payload) => {
    const response = await userService.getUserDetails(payload);
    if (response?.status == 200) {
      return response;
    } else {
      return null;
    }
  }),
  //update account

  // updateEmail
  updateUser: createAsyncThunk(
    "user/update",
    async ({ payload, callback }) => {
      try {
        let response = await userService.updateUserDetails(payload);
        callback(response);
        if (response?.data.status) {
          return response;
        } else {
          return null;
        }
      } catch (error) {
        callback({ status: 0, message: error?.message });
        return null;
      }
    }
  ),
  // update Stripe
  updateUserStripe: createAsyncThunk(
    "/user/update-stripe",
    async ({ payload, callback }) => {
      try {
        let response = await userService.updateUserStripeDetails(payload);
        callback(response);
        if (response?.data.status) {
          return response;
        } else {
          return null;
        }
      } catch (error) {
        callback({ status: 0, message: error?.message });
        return null;
      }
    }
  ),
};

export default userThunkAPI;
