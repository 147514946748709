import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import footerLogo from "@src/./static/img/logo-yellow.svg";

//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        "footer",
        "dashboard"
      ])),
    },
  };
}

const Footer = () => {
  const { t: translate } = useTranslation("dashboard");
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
      setIsClient(true);
    }, []);
  
    const [uniqueLink, setUniqueLink] = useState("");
    const [uniqueLinkErr, setUniqueLinkErr] = useState(null);
    const onHandleInputChange = (e) => {
      const { name, value } = e.target;
  
      setUniqueLink(value.toLowerCase().trim());
      if (!value) {
        setUniqueLinkErr(translate("Please enter your link"));
      } else {
        setUniqueLinkErr("");
      }
    };
  
    const onSubmitForm = (e) => {
      e.preventDefault();
      // throw new Error('This is a custom error message');
      try {
        let err;
        if (!uniqueLink) {
          err = true;
          setUniqueLinkErr(translate("Please enter your link"));
        } else {
          setUniqueLinkErr("");
        }
        if (err) return;
  
        let payload = {
          link: uniqueLink,
        };
      } catch (err) {
        // console.log(err);
      }
    };
  
    const [resources, setIsResources] = useState(false);
    const resourcesClick = () => {
      setIsResources(!resources);
    }
  
    const [company, setIsCompany] = useState(false);
    const companyClick = () => {
      setIsCompany(!company);
    }
  
    const [product, setIsProduct] = useState(false);
    const productClick=()=>{
      setIsProduct(!product);
    }
  
  return (
    <>
      <div className="footerSection">
        <footer className="bg-mor-black-500">
          <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8 lg:pt-24">
            <div className="">
              <div className="block md:grid grid-cols-4 gap-8">
                <div className="mb-6 md:mb-0">
                  <a className="mr-2 block" href="#">
                    <Image src={footerLogo} alt="" />{" "}
                  </a>
                  <p className="grayColorText pb-6 md:pb-8 pt-1">
                    {translate("Powered By")} <a target="_blank" href="https://ads.sweply.com/">Sweply</a>
                  </p>
                  <div className="footerSocial flex">
                    <a className="mr-3" target="_blank" href="https://twitter.com/MORlinks">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.9047 10.8695L21.3513 2.3999H19.5873L13.1187 9.75244L7.956 2.3999H2L9.80867 13.5193L2 22.3999H3.764L10.5907 14.6337L16.044 22.3999H22M4.40067 3.70148H7.11067L19.586 21.1623H16.8753"
                          fill="white"
                        />
                      </svg>
                    </a>
                    <a className="mr-3" target="_blank" href="https://www.linkedin.com/showcase/morlinks/">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.6799 2.31982H4.31992C2.99512 2.31982 1.91992 3.39502 1.91992 4.71982V20.0798C1.91992 21.4046 2.99512 22.4798 4.31992 22.4798H19.6799C21.0047 22.4798 22.0799 21.4046 22.0799 20.0798V4.71982C22.0799 3.39502 21.0047 2.31982 19.6799 2.31982ZM8.15992 9.99982V19.1198H5.27992V9.99982H8.15992ZM5.27992 7.34542C5.27992 6.67342 5.85592 6.15982 6.71992 6.15982C7.58392 6.15982 8.12632 6.67342 8.15992 7.34542C8.15992 8.01742 7.62232 8.55982 6.71992 8.55982C5.85592 8.55982 5.27992 8.01742 5.27992 7.34542ZM18.7199 19.1198H15.8399C15.8399 19.1198 15.8399 14.675 15.8399 14.3198C15.8399 13.3598 15.3599 12.3998 14.1599 12.3806H14.1215C12.9599 12.3806 12.4799 13.3694 12.4799 14.3198C12.4799 14.7566 12.4799 19.1198 12.4799 19.1198H9.59992V9.99982H12.4799V11.2286C12.4799 11.2286 13.4063 9.99982 15.2687 9.99982C17.1743 9.99982 18.7199 11.3102 18.7199 13.9646V19.1198Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                    <a className="mr-3" target="_blank" href="https://www.facebook.com/morlinks">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.36 5.67986H17.76C18.025 5.67986 18.24 5.46482 18.24 5.19986V1.9661C18.24 1.71458 18.0466 1.5053 17.796 1.48754C17.0323 1.4333 15.5405 1.35986 14.4677 1.35986C11.52 1.35986 9.60001 3.12626 9.60001 6.3365V9.51986H6.24001C5.97505 9.51986 5.76001 9.7349 5.76001 9.99986V13.3599C5.76001 13.6248 5.97505 13.8399 6.24001 13.8399H9.60001V22.9599C9.60001 23.2248 9.81505 23.4399 10.08 23.4399H13.44C13.705 23.4399 13.92 23.2248 13.92 22.9599V13.8399H17.3866C17.6314 13.8399 17.8368 13.656 17.8637 13.4127L18.2371 10.0527C18.2688 9.7685 18.0461 9.51986 17.76 9.51986H13.92V7.11986C13.92 6.3245 14.5646 5.67986 15.36 5.67986Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                    <a className="mr-3" target="_blank" href="https://www.instagram.com/MOR.links/">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.99834 2.80005C4.91114 2.80005 2.3999 5.31361 2.3999 8.40161V16.4016C2.3999 19.4888 4.91346 22 8.00146 22H16.0015C19.0887 22 21.5999 19.4865 21.5999 16.3985V8.39849C21.5999 5.31129 19.0863 2.80005 15.9983 2.80005H7.99834ZM17.5999 6.00005C18.0415 6.00005 18.3999 6.35845 18.3999 6.80005C18.3999 7.24165 18.0415 7.60005 17.5999 7.60005C17.1583 7.60005 16.7999 7.24165 16.7999 6.80005C16.7999 6.35845 17.1583 6.00005 17.5999 6.00005ZM11.9999 7.60005C14.6471 7.60005 16.7999 9.75285 16.7999 12.4C16.7999 15.0472 14.6471 17.2 11.9999 17.2C9.3527 17.2 7.1999 15.0472 7.1999 12.4C7.1999 9.75285 9.3527 7.60005 11.9999 7.60005ZM11.9999 9.20005C11.1512 9.20005 10.3373 9.53719 9.73716 10.1373C9.13704 10.7374 8.7999 11.5514 8.7999 12.4C8.7999 13.2487 9.13704 14.0627 9.73716 14.6628C10.3373 15.2629 11.1512 15.6 11.9999 15.6C12.8486 15.6 13.6625 15.2629 14.2626 14.6628C14.8628 14.0627 15.1999 13.2487 15.1999 12.4C15.1999 11.5514 14.8628 10.7374 14.2626 10.1373C13.6625 9.53719 12.8486 9.20005 11.9999 9.20005Z"
                          fill="white"
                        />
                      </svg>
                    </a>

                    {/* <a className="mr-3" target="_blank" href="https://www.tiktok.com/@treepixel">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.3119 6.00922C17.6224 5.57499 17.0557 4.98091 16.6631 4.28082C16.2705 3.58073 16.0644 2.79677 16.0635 2H12.5137L12.5075 15.7801C12.4851 16.3464 12.2914 16.8938 11.9503 17.3541C11.6092 17.8144 11.136 18.1674 10.5894 18.3691C10.0429 18.5707 9.44719 18.6121 8.87652 18.4882C8.30585 18.3642 7.78542 18.0804 7.38001 17.672C6.9746 17.2635 6.70212 16.7486 6.59651 16.1912C6.49089 15.6339 6.5568 15.0587 6.78603 14.5374C7.01526 14.0161 7.39768 13.5716 7.88568 13.2593C8.37369 12.947 8.94571 12.7807 9.53055 12.7811C9.82925 12.782 10.1261 12.8271 10.4107 12.9146V9.40433C10.1191 9.36446 9.82505 9.34382 9.53055 9.34255C5.92875 9.34255 2.99866 12.181 2.99866 15.6713C3.0013 17.3493 3.69052 18.9578 4.91521 20.1442C6.1399 21.3307 7.80013 21.9981 9.53192 22.0003C13.1334 22.0003 16.0635 19.1619 16.0635 15.6713V8.6837C17.5015 9.68627 19.2288 10.2249 21.0004 10.2231V6.78361C20.0455 6.78438 19.1112 6.51525 18.3119 6.00922Z" fill="white"/>
                      </svg>
                    </a> */}
                  </div>
                </div>
                <div className="mb-4 md:mb-0">
                  <h2
                    className="mb-2 text-sm font-semibold grayColorText dark:text-white cursor-pointer md:cursor-default"
                    onClick={resourcesClick}
                  >
                    {translate("Mor")}
                    <span
                      className={
                        !resources
                          ? "absolute right-3 md:hidden"
                          : "absolute right-3 rotate-180 md:hidden"
                      }
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                        data-testid="flowbite-accordion-arrow"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </h2>
                  <ul
                    className={
                      !resources
                        ? "text-gray-500 dark:text-gray-400 font-medium hidden md:block"
                        : "text-gray-500 dark:text-gray-400 font-medium block"
                    }
                  >

                    
                  
                    <li>
                      <Link
                        href="/pricing"
                        className="text-white py-1.5 block hover:text-mor-yellow-500 "
                      >
                        {translate("Pricing")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#Features"
                        className="text-white py-1.5 block hover:text-mor-yellow-500"
                      >
                        {translate("Features")}
                      </Link>
                    </li>
                    <li>
                      <a target="_blank" href="https://help.mor.link/en/"
                        className="text-white py-1.5 block hover:text-mor-yellow-500"
                      >
                        {translate("Blog")}
                      </a>
                    </li>

                    {/* <li>
                      <Link
                        href="/faq"
                        className="text-white py-1.5 block hover:text-mor-yellow-500"
                      >
                        {translate("FAQ")}
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="mb-4 md:mb-0">
                  <h2
                    className="mb-2 text-sm font-semibold grayColorText  dark:text-white cursor-pointer md:cursor-default"
                    onClick={companyClick}
                  >
                     {translate("Support")}
                    <span
                      className={
                        !company
                          ? "absolute right-3 md:hidden"
                          : "absolute right-3 rotate-180 md:hidden"
                      }
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                        data-testid="flowbite-accordion-arrow"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </h2>
                  <ul
                    className={
                      !company
                        ? "text-gray-500 dark:text-gray-400 font-medium hidden md:block"
                        : "text-gray-500 dark:text-gray-400 font-medium block"
                    }
                  >
                    {/* <li>
                      <Link
                        href="/integrations"
                        className="text-white py-1.5 block hover:text-mor-yellow-500"
                      >
                        {translate("Integrations")}
                      </Link>
                    </li> */}

                    <li>
                      <a target="_blank" href="https://help.mor.link/en/"
                        className="text-white py-1.5 block hover:text-mor-yellow-500"
                      >
                        {translate("Help Center")}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://help.mor.link/en/"
                        className="text-white py-1.5 block hover:text-mor-yellow-500"
                      >
                        {translate("Report a Violation")}
                      </a>
                    </li>

                    

                        <li>
                      <Link
                        href="/faq"
                        className="text-white py-1.5 block hover:text-mor-yellow-500"
                      >
                        {translate("FAQ")}
                      </Link>
                    </li>

                     
                  
                  </ul>
                </div>
                <div className="mb-4 md:mb-0">
                  <h2
                    className="mb-2 text-sm font-semibold grayColorText dark:text-white cursor-pointer md:cursor-default"
                    onClick={productClick}
                  >
                   {translate("Terms of Service")}
                    <span
                      className={
                        !product
                          ? "absolute right-3 md:hidden"
                          : "absolute right-3 rotate-180 md:hidden"
                      }
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                        data-testid="flowbite-accordion-arrow"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </h2>
                  <ul
                    className={
                      !product
                        ? "text-gray-500 dark:text-gray-400 font-medium hidden md:block"
                        : "text-gray-500 dark:text-gray-400 font-medium block"
                    }
                  >
                   
                   
                    <li>
                      <Link
                      className="text-white py-1.5 block hover:text-mor-yellow-500"
                      href="/terms-and-service"
                      >
                      {translate("Terms of Service")}
                      </Link>
                    </li>

                    <li>
                      <Link
                      className="text-white py-1.5 block hover:text-mor-yellow-500"
                      href="/privacy-statement"
                      >
                      {translate("Privacy Policy")}
                      </Link>
                    </li>

                    <li>
                      <Link
                      className="text-white py-1.5 block hover:text-mor-yellow-500"
                      href="#"
                      >
                      
                      {translate("Charities")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
           
            <div className="allRights flex justify-center text-sm text-gray-500 pt-8 mt-6">           
                {translate("© 2024")}
                <span className="ml-1 mr-1">Mor</span> 
                 {translate("All Rights Reserved.")}
              
          
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
