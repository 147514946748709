// components/IntercomChat.js
import { useEffect } from "react";
import { useRouter } from "next/router";

const IntercomChat = () => {
  const router = useRouter();
  useEffect(() => {
    // Conditionally load Intercom script based on the current page's path
    let path_status = false;
    let ID;
    switch (router.pathname) {
      case "/":
        path_status = true;
        break;
      case "/dashboard":
        path_status = true;
        break;

      default:
        path_status = false;
        break;
    }
    if (!path_status) {
      // Shut down the Intercom widget for pages where it should not be displayed
      if (typeof window.Intercom !== "undefined") {
        window.Intercom("shutdown");
      }
    } else {
      // Load Intercom script
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${
              path_status ? process.env.INTERCOM_APP_ID : ""
            }`;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }
  }, [router.pathname]);

  return null; // Return null to prevent rendering anything
};

export default IntercomChat;
