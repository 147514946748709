import { createAsyncThunk } from "@reduxjs/toolkit";
import siteService from "@src/services/siteService";

const siteThunkAPI = {
  //create account
  createOrderAsync: createAsyncThunk("createOrder", async (payload) => {
    const response = await siteService.createOrder(payload);
    if (response?.data?.status) {
      return response;
    } else {
      return null;
    }
  }),

  //get category, subcategory card list
  getCategorySubcategoryListAsync: createAsyncThunk("/api/site/get-cards-by-category", async (payload) => {
    const response = await siteService.getCategorySubcategoryList(payload);
    if (response?.status == 200) {
      return response;
    } else {
      return null;
    }
  }),


  //get user site list
  getUserSiteListAsync: createAsyncThunk(`/api/site/GetMySitesList`, async (payload) => {
    const response = await siteService.getUserSiteList(payload);
    if (response?.status == 200) {
      return response;
    } else {
      return null;
    }
  }),

  // get Contact Card details
  getContactCardAsync: createAsyncThunk(`/api/site/get-contact-card-data-by-id/`, async (payload) => {
    const response = await siteService.getContactCard(payload);
    if (response?.status == 200) {
      return response;
    } else {
      return null;
    }
  }),

  // get Contact Card details
  getContactCardDetailsBySiteIdAsync: createAsyncThunk(`/api/site/get-contact-card-data-by-site_id/`, async (payload) => {
    const response = await siteService.getContactCardDetailsBySiteId(payload);
    if (response?.status == 200) {
      return response;
    } else {
      return null;
    }
  }),

  // get Contact Card delete
  getContactCardDeleteAsync: createAsyncThunk(`/api/site/contact-card-delete/`, async (payload) => {
    const response = await siteService.getContactCardDelete(payload);
    if (response?.status == 200) {
      return response;
    } else {
      return null;
    }
  }),

  createUpdateContactCard: createAsyncThunk(
    "site/create-update-contact-card",
    async ({ payload, callback }) => {
      try {
        const response = await siteService.createUpdateContactCard(payload);
        callback(response);
        if (response?.data.status) {
          return response;
        } else {
          return null;
        }
      } catch (error) {
        callback({ status: 0, message: error?.message });
        return null;
      }
    }
  ),

  SiteUpdate: createAsyncThunk(
    "site/update",
    async ({ payload, callback }) => {
      try {
        const response = await siteService.SiteUpdate(payload);
        callback(response);
        if (response?.data.status) {
          return response;
        } else {
          return null;
        }
      } catch (error) {
        callback({ status: 0, message: error?.message });
        return null;
      }
    }
  ),

  setCustomizeTheme: createAsyncThunk("api/site/create-customization-stylelook",
  async ({ payload, callback }) => {
    try {
      const response = await siteService.createCustomizeStyle(payload);
      callback(response);
      if (response?.data.status) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      callback({ status: 0, message: error?.message });
      return null;
    }
  })
};

export default siteThunkAPI;
