import router from "next/router";
import axiosInstance from "./axios";
import { getHeaders } from "./apiHeaders";

let headers = {};
let response = "";

const userService = {

  //create user data
  getUserDetails: async function (payload) {
    try {
      let response = await axiosInstance.get(`/api/user/users-details/${payload}`);
      return response?.data
    } catch (error) {
      return error?.response?.data;
    }
  },

  //update user data
  updateUserDetails: async function (payload) {
    try {
      let response = await axiosInstance.patch(`/api/user/update`, payload);
      return response?.data
    } catch (error) {
      return error?.response?.data;
    }
  },

  //update Stripe
  updateUserStripeDetails: async function (payload) {
    try {
      let response = await axiosInstance.patch(`/api/user/update-stripe`, payload);
      return response?.data
    } catch (error) {
      return error?.response?.data;
    }
  },

  //change user password
  changeUserPassword: async function (payload) {
    try {
      let response = await axiosInstance.patch("/api/user/change-password", payload)
      return response?.data
    } catch (error) {
      return error?.response?.data
    }
  }


};

export default userService;