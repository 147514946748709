import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/Statuses";
import commonThunkAPI from "./middleware";

// const userToken = localStorage.getItem("userToken")
// ? localStorage.getItem("userToken") : null

const initialState = {
  fileUpload: null,
  status: STATUSES.IDLE,
  deleteStatus: false,
  countryWiseSettings: null,
  loadingCountryWiseSettings: false,
  loadingCountry: false,
  loadingRegion: false,
  countryList: null,
  regionList: null,
};

const commonSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    clearAllStatus: (state, action) => {
      state.deleteStatus = false;
    },
  },
  extraReducers: {
    [commonThunkAPI.uploadFilesAsync.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [commonThunkAPI.uploadFilesAsync.fulfilled]: (state, action) => {
      if (!action.payload) {
        state.videoList = [];
      } else {
        state.fileUpload = action?.payload?.data;
      }
      state.status = STATUSES.IDLE;
    },
    [commonThunkAPI.uploadFilesAsync.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
    },
    // getCountryWiseSettings
    [commonThunkAPI.getCountryWiseSettings.pending]: (state, action) => {
      state.loadingCountryWiseSettings = true;
    },
    [commonThunkAPI.getCountryWiseSettings.fulfilled]: (state, action) => {
      state.countryWiseSettings = action?.payload;
      state.loadingCountryWiseSettings = false;
    },
    [commonThunkAPI.getCountryWiseSettings.rejected]: (state, action) => {
      state.loadingCountryWiseSettings = false;
    },
    // get Country
    [commonThunkAPI.getCountryAsync.pending]: (state, action) => {
      state.loadingCountry = true;
    },
    [commonThunkAPI.getCountryAsync.fulfilled]: (state, action) => {
      state.countryList = action?.payload;
      state.loadingCountry = false;
    },
    [commonThunkAPI.getCountryAsync.rejected]: (state, action) => {
      state.loadingCountry = false;
    },
    // get region
    [commonThunkAPI.getRegionAsync.pending]: (state, action) => {
      state.loadingRegion = true;
    },
    [commonThunkAPI.getRegionAsync.fulfilled]: (state, action) => {
      state.regionList = action?.payload;
      state.loadingRegion = false;
    },
    [commonThunkAPI.getRegionAsync.rejected]: (state, action) => {
      state.loadingRegion = false;
    },
  },
});

export const { clearAllStatus } = commonSlice.actions;
export default commonSlice.reducer;
