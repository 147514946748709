const translations = {
    'text1': {
      'en': `From Reach to Results:`,
      'ar': 'من الوصول إلى النتائج:',
    },
    'text2': {
      'en': `Let's gather all your links under`,
      'ar': 'دعونا نجمع كل الروابط الخاصة بك تحت',
    },
    'text3': {
      'en': ' one cool roof!',
      'ar': 'سقف واحد بارد!',
    },
    'text4': {
      'en': `With Treepixel, life's simpler. We unite all your links, creating a friendly online corner that's all about showcasing what you do best.`,
      'ar': 'مع Treepixel، الحياة أبسط. نحن نجمع كل الروابط الخاصة بك، وننشئ ركنًا ودودًا عبر الإنترنت يدور حول عرض أفضل ما تفعله.',
    },
    'text5': {
      'en': `Secure your unique username/link today:`,
      'ar': 'قم بتأمين اسم المستخدم/الرابط الفريد الخاص بك اليوم:',
    },
    'username': {
      'en': `username`,
      'ar': 'اسم المستخدم',
    },
    'text6': {
      'en': `Made for everyone, crafted for results.`,
      'ar': 'مصنوع للجميع، مصنوع لتحقيق النتائج.',
    },
    'text7': {
      'en': `Whether you're a crafty creator, a foodie hotspot, or somewhere in-between, Treepixel's got your back. We're here to turn every click into a conversation, fostering connections that truly matter.`,
      'ar': 'سواء كنت مبدعًا ماهرًا، أو من عشاق الطعام، أو في مكان ما بينهما، فإن Treepixel تدعمك. نحن هنا لتحويل كل نقرة إلى محادثة، وتعزيز الاتصالات المهمة حقًا.',
    },
    'text8': {
      'en': `Claim your link now`,
      'ar': 'المطالبة بالرابط الخاص بك الآن',
    },
    'Claim your link': {
      'en': `Claim your link`,
      'ar': 'المطالبة بالرابط الخاص بك',
    },
    'text9': {
      'en': `No Credit / Debit Card Required*`,
      'ar': 'لا حاجة لبطاقة الائتمان/الخصم*',
    },
    'text10': {
      'en': `Diverse platforms, All in One Place!`,
      'ar': 'منصات متنوعة، الكل في مكان واحد!',
    },
    'text11': {
      'en': `Diverse Integration: Hook up with 70+ platforms. No fuss.`,
      'ar': 'التكامل المتنوع: تواصل مع أكثر من 70 منصة. لا ضجة.',
    },
    'text12': {
      'en': `Optimized Transactions: Streamline your business tools and payment processes.`,
      'ar': 'المعاملات المحسنة: قم بتبسيط أدوات عملك وعمليات الدفع.',
    },
    'text13': {
      'en': `Simple & User-Friendly: Build your digital world with our easy-breezy editor.`,
      'ar': 'بسيط وسهل الاستخدام: قم ببناء عالمك الرقمي باستخدام محررنا سهل الاستخدام.',
    },
    'text14': {
      'en': `Analytics: Your Treasure Map to Growth.`,
      'ar': 'التحليلات: خريطة الكنز الخاص بك للنمو.',
    },
    'text15': {
      'en': `With Treepixel, every click has a story. Get to know your audience better, find out what they like, where they're from, and how you can grow.`,
      'ar': 'مع Treepixel، كل نقرة لها قصة. تعرف على جمهورك بشكل أفضل، واكتشف ما يحبونه، ومن أين أتوا، وكيف يمكنك النمو.',
    },
    'text16': {
      'en': `And hey, we've got Google Analytics on our team, too!`,
      'ar': 'ومرحبًا، لدينا Google Analytics ضمن فريقنا أيضًا!',
    },
    'text17': {
      'en': `Craft Your Corner: Fast, Fun, and Fully You.`,
      'ar': 'اصنع ركنك: سريع وممتع ويمثلك تمامًا.',
    },
    'text18': {
      'en': `TreePixel lets you reconnect with your world, your way. Get your profile up and running in no time. It&apos;s not just a landing page - it&apos;s your own, personalized canvas. Set it up to reflect you, engage your crowd, and boost your online presence.`,
      'ar': 'يتيح لك TreePixel إعادة الاتصال بعالمك بطريقتك الخاصة. احصل على ملف التعريف الخاص بك وتشغيله في أي وقت من الأوقات. إنها ليست مجرد صفحة مقصودة - إنها لوحة الرسم الشخصية الخاصة بك. قم بإعداده ليعكس شخصيتك، ويشرك جمهورك، ويعزز تواجدك على الإنترنت.',
    },
    // Add more translations as needed
  };
  
  const translateText = (text, language) => {
    const translatedText = translations[text] ? translations[text][language] : text;
    return translatedText || text;
  };
  
  export { translateText };
  