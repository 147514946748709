export const routesToBackToDashboard = [
  "/",
  "/dashboard",
  "/plan-billing",
  "/profile-setting",
  "/terms-and-service",
  "/privacy-statement",
  "/auth/sign-in",
  "/auth/sign-up",
  "/faq",
  "/404",
  "/verify-email",
  "/verified-email",
];

export const routesToShowHeader = [
  "/",
  "/auth/signin",
  "/auth/forget-password",
  "/auth/otp",
  "/auth/signup",
  "/pricing",
  "/preview/[id]",
  "/integrations",
  "/[sitename]",
  "/terms-and-service",
  "/privacy-statement",
  "/auth/sign-in",
  "/auth/sign-up",
  "/faq",
  "/404",
  "/verify-email",
  "/verified-email",
];

export const routesToShowFooter = [
  "/",
  "/pricing",
  "/integrations",
  "/terms-and-service",
  "/privacy-statement",
  "/faq",
];

export const routesToShowSidebar = [
  "/",
  "/auth/signin",
  "/auth/forget-password",
  "/auth/otp",
  "/auth/signup",
  "/my-site",
  "/my-site/site-setting",
  "/my-site/site-analytics",
  "/my-site/integration",
  "/my-site",
  "/pricing",
  "/preview/[id]",
  "/integrations",
  "/[sitename]",

  "/auth/sign-up",
  "/auth/sign-in",
  "/faq",
  "/terms-and-service",
  "/privacy-statement",
  "/404",
  "/verify-email",
  "/verified-email",
];

export const routesToShowSiteSidebar = [
  "/",
  "/auth/signin",
  "/auth/forget-password",
  "/auth/otp",
  "/auth/signup",
  "/dashboard",
  "/plan-billing",
  "/profile-setting",
  "/pricing",
  "/earn-with-us",
  "/preview/[id]",
  "/integrations",
  "/[sitename]",

  "/auth/sign-up",
  "/auth/sign-in",
  "/faq",
  "/terms-and-service",
  "/privacy-statement",
  "/404",
  "/verify-email",
  "/verified-email",
];

export const custValidator = {
  //returns true if no is float else false
  isFloat: (value) => value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/),
  isNumber: (value) => value.match(/^[0-9\b]+$/),
  isEmpty: (value) => value !== undefined && value !== null && value !== "",
  isCharecters: (value) => value.match(/^[A-Za-z ]+$/),
  isValidEmail: (value) => {
    return !!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  },
};
export const dataURLtoBlob = (dataURL) => {
  const parts = dataURL.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const byteString = atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: contentType });
};

export const stripHtmlTags = (htmlString) => {
 try {
   // Create a new element and set its innerHTML to the provided string
   let tempElement = document.createElement("div");
   tempElement.innerHTML = htmlString;
   
   // Return the text content of the element, which will be without HTML tags
   return tempElement.textContent || tempElement.innerText || "";
 } catch (error) {
  return null;
 }
}

export const OTP_LENGTH = 6;
