import React, { useState } from "react";
import Link from "next/link";
import { Dropdown, Avatar } from "flowbite-react";
import Image from "next/image";
import logoIcon from "@src/static/img/logo-icon.svg";
//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        "dashboard"
        
      ])),
    },
  };
}

const BackToDash = () => { 
  const { t: translate } = useTranslation("dashboard"); 
  return (
    <div>
        <Link href="/dashboard" className="hidden sm:flex items-center font-medium text-sm sm:text-lg">
            <svg className="mr-3 rtl:mr-0 rtl:ml-3 rtl:rotate-180" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.30253 0.710469C7.69253 1.10047 7.69253 1.73047 7.30253 2.12047L3.42253 6.00047H18.5925C19.1425 6.00047 19.5925 6.45047 19.5925 7.00047C19.5925 7.55047 19.1425 8.00047 18.5925 8.00047H3.41253L7.29253 11.8805C7.68253 12.2705 7.68253 12.9005 7.29253 13.2905C6.90253 13.6805 6.27253 13.6805 5.88253 13.2905L0.29253 7.70047C-0.0974693 7.31047 -0.0974693 6.68047 0.29253 6.29047L5.89253 0.710469C6.27253 0.320469 6.91253 0.320469 7.30253 0.710469Z" fill="#6F7582"/>
            </svg>
            {translate("Back to Dashboard")}
        </Link>
    </div>
  )
}
export default BackToDash;