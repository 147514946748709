import React from "react";
import { useRouter } from "next/router";

const LanguageSwitcher = () => {
  const { locale, locales, push, pathname, query } = useRouter();

  //   const handleSwichLanguage = (l) => {
  //     localStorage.setItem("lang", l);
  //     // router.pathname
  //     console.log("pathname123",pathname)
  //     push(pathname, undefined, { locale: l });
  //     /* push('/about', undefined, { locale: l }) */

  //     /* Not recommeneded */
  //     /* push(`/${l}/about`) */

  //     /* Not recommeneded */
  //     /* push(`/${l}`) */
  //     /* push(`/${l}/about`) */
  //   };

  const handleSwichLanguage = (language) => {
    localStorage.setItem("language", language);
    let newPathname = pathname;
    let newQuery = { ...query };

   

    // If it's a dynamic route, include the dynamic parameter in the query
    if (query.id) {
      newQuery.id = query.id;
    }

    // If you're in a simple route, newQuery.id might be undefined, so remove it
    if (!newQuery.id) {
      delete newQuery.id;
    }

    push({ pathname: newPathname, query: newQuery }, undefined, {
      locale: language,
    });
  };

  return (
    <>
      <div className="headerLang d-flex align-items-center mr-4 rtl:ml-4 rtl:mr-0">
        <div  className="flex items-center cursor-pointer"
          
          onClick={() => handleSwichLanguage(locale == "ar" ? "en" : "ar")}
        >
          <svg className="mr-2 rtl:mr-0 rtl:ml-2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1277_211857)">
              <path
                d="M9.99199 1.66699C5.39199 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.39199 18.3337 9.99199 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 9.99199 1.66699ZM15.767 6.66699H13.3087C13.042 5.62533 12.6587 4.62533 12.1587 3.70033C13.692 4.22533 14.967 5.29199 15.767 6.66699ZM10.0003 3.36699C10.692 4.36699 11.2337 5.47533 11.592 6.66699H8.40866C8.76699 5.47533 9.30866 4.36699 10.0003 3.36699ZM3.55033 11.667C3.41699 11.1337 3.33366 10.5753 3.33366 10.0003C3.33366 9.42533 3.41699 8.86699 3.55033 8.33366H6.36699C6.30033 8.88366 6.25033 9.43366 6.25033 10.0003C6.25033 10.567 6.30033 11.117 6.36699 11.667H3.55033ZM4.23366 13.3337H6.69199C6.95866 14.3753 7.34199 15.3753 7.84199 16.3003C6.30866 15.7753 5.03366 14.717 4.23366 13.3337ZM6.69199 6.66699H4.23366C5.03366 5.28366 6.30866 4.22533 7.84199 3.70033C7.34199 4.62533 6.95866 5.62533 6.69199 6.66699ZM10.0003 16.6337C9.30866 15.6337 8.76699 14.5253 8.40866 13.3337H11.592C11.2337 14.5253 10.692 15.6337 10.0003 16.6337ZM11.9503 11.667H8.05033C7.97533 11.117 7.91699 10.567 7.91699 10.0003C7.91699 9.43366 7.97533 8.87533 8.05033 8.33366H11.9503C12.0253 8.87533 12.0837 9.43366 12.0837 10.0003C12.0837 10.567 12.0253 11.117 11.9503 11.667ZM12.1587 16.3003C12.6587 15.3753 13.042 14.3753 13.3087 13.3337H15.767C14.967 14.7087 13.692 15.7753 12.1587 16.3003ZM13.6337 11.667C13.7003 11.117 13.7503 10.567 13.7503 10.0003C13.7503 9.43366 13.7003 8.88366 13.6337 8.33366H16.4503C16.5837 8.86699 16.667 9.42533 16.667 10.0003C16.667 10.5753 16.5837 11.1337 16.4503 11.667H13.6337Z"
                fill="#fff"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_1277_211857">
                <rect width="20" height="20" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          {locale == "ar" ? <span>En</span> : <span className="ar-font">عربي</span>}
        </div>
      </div>
    </>
  );
};

export default LanguageSwitcher;

//test