import { useEffect, useState } from "react";
import "@src/styles/globals.css";
import { Provider } from "react-redux";
import store from "../store";
import Script from "next/script";
import Head from "next/head";
import Layout from "@src/components/layout/Layout";
import { IpDetection } from "@src/utils/ip-detection";
import { useRouter } from "next/router";
// language
import { appWithTranslation } from "next-i18next";
import nextI18nConfig from "../../next-i18next.config";
import { useTranslation } from "next-i18next";

//AUTH0
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { ThemeProvider } from "@src/components/ThemeContext";
import { CurrencyProvider } from "@src/components/CurrencyContext";
import axios from "axios";

// Use your Rewardful API Key
const API_KEY = process.env.NEXT_PUBLIC_REWARDFUL_DEMO_API_KEY;

// If not setting NEXT_PUBLIC_APP_REWARDFUL_SCRIPT_URL, just use https://r.wdfl.co/rw.js
const SCRIPT_URL = process.env.NEXT_PUBLIC_APP_REWARDFUL_SCRIPT_URL;

function App({ Component, pageProps }) {
  const { locale, query, pathname, push } = useRouter();
  const router = useRouter();

  const [countryCode, setCountryCode] = useState(pageProps.initialCountryCode);
  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) return;
    const changeLanguage = async () => {
      const response = await fetch("/api/getCountryCode");
      const data = await response.json();
      setCountryCode(data.country);
      const gulfCountryCodes = ["SA", "QA", "KW", "AE", "OM", "BH"];
      const newLocale = gulfCountryCodes.includes(countryCode) ? "ar" : "en";
      localStorage.setItem("language", newLocale);
      let newPathname = pathname;
      let newQuery = { ...query };

      if (query.id) {
        newQuery.id = query.id;
      }

      if (!newQuery.id) {
        delete newQuery.id;
      }

      await push(
        {
          pathname: `/${newLocale}${newPathname}`,
          query: newQuery,
        },
        undefined,
        { locale: newLocale }
      );
    };

    changeLanguage();
  }, [countryCode]);
  useEffect(() => {
    if (router.pathname == "/[sitename]") return;
    if (locale == "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, [locale]);

  return (
    <ThemeProvider>
      <CurrencyProvider>
        <div
          // className={
          //   locale === "ar" && router.pathname !== "/[sitename]"
          //     ? typeof window !== "undefined"
          //       ? localStorage.getItem("language") == "ar"
          //       : ""
          //       ? "ar-style"
          //       : ""
          //     : ""
          // }
          className={
            locale === "ar" && router.pathname !== "/[sitename]"
              ? "ar-style"
              : ""
          }
          // className={
          //   typeof window !== "undefined"
          //     ? localStorage.getItem("language") === "ar"
          //     : false && router.pathname !== "/[sitename]" && locale === "ar"
          //     ? "ar-style"
          //     : typeof window !== "undefined"
          //     ? localStorage.getItem("language") === "en"
          //     : false
          //     ? "" && locale === "en"
          //     : "ar-style"
          // }
        >
          <Head>
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap"
              rel="stylesheet"
            ></link>
            {/* <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap" rel="stylesheet"></link> */}
            {/* <link href="https://fonts.googleapis.com/css2?family=Almarai:wght@400;700;800&display=swap" rel="stylesheet"></link> */}

            <link
              href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap"
              rel="stylesheet"
            ></link>
            <link
              href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&family=Roboto:wght@400;500&display=swap"
              rel="stylesheet"
            ></link>
            <link
              href="https://fonts.googleapis.com/css2?family=Comic+Neue&family=IBM+Plex+Sans:wght@400;500;600;700&family=Roboto:wght@400;500&display=swap"
              rel="stylesheet"
            ></link>
          </Head>
          <Provider store={store}>
            <UserProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
              <Script src={SCRIPT_URL} data-rewardful={API_KEY} />
              <Script
                id="rewardful-queue"
                strategy="beforeInteractive"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
                }}
              />
            </UserProvider>
          </Provider>
        </div>
      </CurrencyProvider>
    </ThemeProvider>
  );
}
App.getInitialProps = async ({ Component, ctx }) => {
  const { req } = ctx;
  const additionalData = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};
  if (req) {
    return { initialCountryCode: req.geo ? req.geo.country : null };
  }
  return {
    pageProps: {
      ...additionalData,
      initialCountryCode: null,
    },
  };
};
export default appWithTranslation(App, nextI18nConfig);
