import router from "next/router";
import axiosInstance from "./axios";
import { getHeaders } from "./apiHeaders";

let headers = {};
let response = "";

const commonService = {
  uploadFiles: async function (payload) {
    try {
      let headers = {
        "Content-Type": "multipart/form-data",
        authorization: "",
      };
      return axiosInstance.patch(
        `/api/site/file-uploaded/${payload?.type}`,
        payload,
        { headers }
      );
    } catch (error) {
      return error?.response?.data;
    }
  },

  //check site link url uniqueness
  checkUniqueUrl: async function (payload) {
    try {
      let response = await axiosInstance.get(`/api/site/check-url/${payload}`);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  //check user unique email
  checkUniqueEmail: async function (payload) {
    try {
      let response = axiosInstance.get(
        `/api/user/check-email/${payload?.email}/${payload?.userId}`
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  },

  //get country
  getCountry: async function (payload) {
    try {
      let response = await axiosInstance.get(`/api/site/get-country`);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  //get region
  getRegion: async function (payload) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-region-by-country-id/${payload}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  //get city
  getCity: async function (payload) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-city-by-region-id/${payload}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getShopifyProducts: async function (payload) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-site-cards/${payload.site_id}/${payload.user_id}/${payload?.locale}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getSelectedProducts: async function (payload) {
    try {
      let response = await axiosInstance.get(
        `/api/site/get-site-preview/${payload}`
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getPlanData: async function () {
    try {
      let response = await axiosInstance.get(`/api/site/get-plan-data`);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
};

export default commonService;
