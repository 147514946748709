import { createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../../../../services/commonService";

const commonThunkAPI = {
  uploadFilesAsync: createAsyncThunk("uploadFiles", async (payload) => {
    const response = await commonService.uploadFiles(payload);
    return response;
  }),
  getCountryWiseSettings: createAsyncThunk(
    "getCountryWiseSettings",
    async (payload) => {
      const response = await commonService.getCountryWiseSettings(payload);
      if (response?.data?.status) {
        return response?.data?.data;
      } else {
        return null;
      }
    }
  ),
  getCountryAsync: createAsyncThunk(
    "getCountry",
    async (payload) => {
      const response = await commonService.getCountry(payload);
      if (response?.data?.status) {
        return response?.data?.data;
      } else {
        return null;
      }
    }
  ),
  getRegionAsync: createAsyncThunk(
    "getRegion",
    async (payload) => {
      const response = await commonService.getRegion(payload);
      if (response?.data?.status) {
        return response?.data?.data;
      } else {
        return null;
      }
    }
  ),
};

export default commonThunkAPI;
