
//new
const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend= require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default

const isBrowser = typeof window !== 'undefined'
const isDev = false

module.exports = {
  defaultNS: "header",
  i18n: {
    defaultLocale: "ar",
    locales: ["en", "ar"],
    localeDetection: true,
    fallbackLng: "en",
    reloadOnPrerender: false,
    //reloadOnPrerender updates to your translation JSON files without
    // having to restart your development server each time. 
    returnObjects: true,
  },
  //new
  debug: isDev,
  backend: {
    backendOptions: [{ expirationTime: isDev ? 0 : 60 * 60 * 1000 }, {}], // 1 hour
    backends: isBrowser ? [LocalStorageBackend, HttpBackend]: [],
  },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  
};





