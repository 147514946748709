import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import SiteSidebar from "./SiteSidebar";
import { useRouter } from "next/router";
import { Helmet } from "react-helmet";
import commonService from "@src/services/commonService";
// users details
import userThunkAPI from "@src/store/features/user/middleware";
import {
  routesToShowHeader,
  routesToShowSidebar,
  routesToShowSiteSidebar,
  routesToShowFooter,
} from "../../utils/helper";

//services
import IntercomChat from "@src/components/IntercomChat";
//store
import { useDispatch, useSelector } from "react-redux";
import {
  setUser,
  userSignOut,
  setUserData,
} from "@src/store/features/user/userSlice";
import { getAuthToken, setCurrentUser } from "@src/utils/auth";

// laungage translate
import { translateText } from "@src/utils/translate";
//AUTH0
import { useUser } from "@auth0/nextjs-auth0/client";
//crypto js
const crypto = require("crypto-js");

function initializeIntercom(userDetails) {
  try {
    const helmetScript = `
  window.intercomSettings = {
    app_id: "w5jpj8sz",
    api_base: "https://api-iam.intercom.io",
    user_id: null,
    email: null,
    name: null,
  };
`;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.text = helmetScript;

    window.document.head.appendChild(script);

    if (userDetails?.userId) {
      const userId = userDetails.userId.toString();
      const intercom_hash = crypto
        .HmacSHA256(userId, process.env.INTERCOM_SECRET_KEY)
        .toString(crypto.enc.Hex);

      const updateScript = window.document.createElement("script");
      updateScript.type = "text/javascript";
      updateScript.text = `
    window.Intercom('update', {
      app_id: "w5jpj8sz",
    api_base: "https://api-iam.intercom.io",
      user_id: '${userDetails.userId}',
      email: '${userDetails.email}',
      name: '${userDetails.first_name} ${userDetails.last_name}',
      plan_id: '${userDetails.subscription_status}',
      user_hash: '${intercom_hash}',
      mobile: '${userDetails?.phone_number}',
    });
  `;
      window.document.head.appendChild(updateScript);
    }
  } catch (error) {}
}

const Layout = (props) => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { userLoginData, userDetails } = useSelector((state) => state.user);
  const attributes = userLoginData?.attributes;
  const router = useRouter();
  let showHeader = routesToShowHeader.includes(router.pathname);
  let showFooter = routesToShowFooter.includes(router.pathname);
  let showSidebar = routesToShowSidebar.includes(router.pathname);
  let showSiteSidebar = routesToShowSiteSidebar.includes(router.pathname);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  useEffect(() => {
    if (user?.sub) {
      getToken();
    }
  }, [user]);

  useEffect(() => {
    if (router.pathname == "/dashboard" || router.pathname == "/") {
      initializeIntercom(userDetails);
    }
  }, [userDetails]);
  const getToken = async () => {
    try {
      if (user) {
        try {
          const response = await fetch("/api/auth/authtoken", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sub}`, // Replace 'user.sub' with the actual access token
            },
          });
          if (response.status === 200) {
            const data = await response.json();
            dispatch(setUserData(userDetails));
            localStorage.setItem(
              "auth0Token",
              `Bearer ${data?.data?.access_token}`
            );
            try {
              dispatch(userThunkAPI.getUserDetailsAsync(user?.sub));
            } catch (error) {}
          }
        } catch (error) {
          // console.error("API Request Error", error);
        }
      }
    } catch (error) {
      // console.log('55555555555555555 ', error);
    }
  };
  return (
    <div className={` ${currentLanguage === "ar" ? "rtl" : ""}`}>
      <IntercomChat />

      {/* {!showHeader && <Header />} */}
      {!showSidebar && <Sidebar pathname={router.pathname} />}
      {!showSiteSidebar && <SiteSidebar />}
      <div>{props?.children}</div>
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;

{
  /* <div>
{getAuthToken() && !showHeader && <Header />}
{getAuthToken() && !showSidebar && <Sidebar pathname={router.pathname} />}
{getAuthToken() && !showSiteSidebar && <SiteSidebar />}
<div>{props?.children}</div>
{showFooter && <Footer />}
</div> */
}
