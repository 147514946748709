import { createSlice, current } from "@reduxjs/toolkit";
import { STATUSES } from "../../../utils/Statuses";
import siteThunkAPI from "./middleware";

// const userToken = localStorage.getItem("userToken")
// ? localStorage.getItem("userToken") : null

const initialState = {
  catSubCatCardList: null,
  userSiteList: null,
  contactCard: null,
  contactCardDetails: null,
  contactCardDetailsBySiteId: null,
  contactCardDelete: null,
  siteData: null,
  siteUpdate: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    addRemoveCards: (state, action) => {

      // let outerIndex = state.catSubCatCardList.findIndex((item) => item?.id == action?.payload?.id)
      // console.log("outerIndex123", outerIndex)
      // let outerTempObj = state.catSubCatCardList[outerIndex]

      // console.log("outerTempObj", outerTempObj)
      // let innerIndex = outerTempObj.cards.findIndex((item) => item?.card_id == action?.payload?.card_id)
      // let innerTempObj = outerTempObj.cards[innerIndex]
      // if (innerTempObj.selected)
      //   innerTempObj.selected = false
      // else
      //   innerTempObj.selected = true

      // outerTempObj.cards[innerIndex] = innerTempObj
      // state.catSubCatCardList[outerIndex] = outerTempObj
      // return {
      //   ...state,
      //   ...state.catSubCatCardList
      // }

      // state.catSubCatCardList.map((item, index) => {
      //   console.log(item, "reducccccccccccccccccccccccccc")
      //   if (item?._id == action?.payload) {
      //     item.isLiked = !item.isLiked;
      //   }
      // });
    },
    // clearAllStatus: (state, action) => {
    //   state.catSubCatCardList = null
    // },

  },
  extraReducers: {

    //get category, sub category list
    [siteThunkAPI.pending]: (state, action) => {
      state.catSubCatCardList = STATUSES.LOADING;
    },

    [siteThunkAPI.getCategorySubcategoryListAsync.fulfilled]: (state, action) => {
      state.catSubCatCardList = action?.payload?.data?.data;
    },
    [siteThunkAPI.getCategorySubcategoryListAsync.rejected]: (state, action) => {
      state.catSubCatCardList = STATUSES.ERROR;
    },


   //get user site list
    [siteThunkAPI.getUserSiteListAsync.pending]: (state, action) => {
      state.userSiteList = STATUSES.LOADING;
    },

    [siteThunkAPI.getUserSiteListAsync.fulfilled]: (state, action) => {
      state.userSiteList = action?.payload?.data?.data;
    },
    [siteThunkAPI.getUserSiteListAsync.rejected]: (state, action) => {
      state.userSiteList = STATUSES.ERROR;
    },

   //get Contact Card
    [siteThunkAPI.getContactCardAsync.pending]: (state, action) => {
      state.contactCardDetails = STATUSES.LOADING;
    },
    [siteThunkAPI.getContactCardAsync.fulfilled]: (state, action) => {
      state.contactCardDetails = action?.payload?.data?.data;
    },
    [siteThunkAPI.getContactCardAsync.rejected]: (state, action) => {
      state.contactCardDetails = STATUSES.ERROR;
    },

   //create / Update Contact Card
    [siteThunkAPI.createUpdateContactCard.pending]: (state, action) => {
      state.contactCard = STATUSES.LOADING;
    },
    [siteThunkAPI.createUpdateContactCard.fulfilled]: (state, action) => {
      state.contactCard = action?.payload?.data?.data;
    },
    [siteThunkAPI.createUpdateContactCard.rejected]: (state, action) => {
      state.contactCard = STATUSES.ERROR;
    },

   //create / Update Contact Card
    [siteThunkAPI.getContactCardDetailsBySiteIdAsync.pending]: (state, action) => {
      state.contactCardDetailsBySiteId = STATUSES.LOADING;
    },
    [siteThunkAPI.getContactCardDetailsBySiteIdAsync.fulfilled]: (state, action) => {
      state.contactCardDetailsBySiteId = action?.payload?.data?.data;
    },
    [siteThunkAPI.getContactCardDetailsBySiteIdAsync.rejected]: (state, action) => {
      state.contactCardDetailsBySiteId = STATUSES.ERROR;
    },

   //Contact Card delete
    [siteThunkAPI.getContactCardDeleteAsync.pending]: (state, action) => {
      state.contactCardDelete = STATUSES.LOADING;
    },
    [siteThunkAPI.getContactCardDeleteAsync.fulfilled]: (state, action) => {
      state.contactCardDelete = action?.payload?.data?.data;
    },
    [siteThunkAPI.getContactCardDeleteAsync.rejected]: (state, action) => {
      state.contactCardDelete = STATUSES.ERROR;
    },

   //SiteUpdate
    [siteThunkAPI.SiteUpdate.pending]: (state, action) => {
      state.SiteUpdate = STATUSES.LOADING;
    },
    [siteThunkAPI.SiteUpdate.fulfilled]: (state, action) => {
      state.SiteUpdate = action?.payload?.data?.data;
    },
    [siteThunkAPI.SiteUpdate.rejected]: (state, action) => {
      state.SiteUpdate = STATUSES.ERROR;
    },
  },
});

export const { addRemoveCards } = siteSlice.actions;
export default siteSlice.reducer;
