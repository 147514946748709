import cookies from "js-cookie";
import { parseCookies } from "nookies";
import { withPageAuthRequired } from "@auth0/nextjs-auth0";
// import { cookies } from "next/headers";

// const cookieStore = cookies();

export const setCurrentUser = (dataObj) => {
  cookies.set("v2user", JSON.stringify(dataObj));
};

export const getCurrentUser = () => {
  const allCookies = parseCookies();
  // if (allCookies?.v2user) return JSON.parse(allCookies?.v2user);
  if (localStorage.getItem("userToken"))
    return JSON.parse(localStorage.getItem("userToken"));
  else return null;
};

export const getAuthToken = () => {
  // return getCurrentUser()?.jwtToken || "";
  return true;
};

export function withAuth(Component) {
  return withPageAuthRequired({
    getServerSideProps: async (context) => {
      const { id } = context.query;
      return {
        props: { id: id || null },
      };
    },
  })(Component);
}

export const removeCurrentUser = async () => {
  // cookies.remove("XSRF-TOKEN");
  // cookies.remove("cognito");
  localStorage.removeItem("userToken");
  // cookies.remove("v2user");
};
