import axios from "axios";
import * as secure from "../utils/secure";
import { getAuthToken, setCurrentUser } from "@src/utils/auth";
import { getHeaders } from "../services/apiHeaders";
const isEncryptDecryptEnabled =
  process.env.IS_ENCRYPT_DECRYPT_ENABLED == "ENABLED";

// create instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// request middleware
axiosInstance.interceptors.request.use(
  async (config1) => {
    let config = config1;

    // console.log("ax request in axios interceptors brfore", config.data);
    //set token
    // console.log("window1111",window)
    if (typeof window !== "undefined") {
      // const user = JSON.parse(localStorage.getItem("user"));
      // config.headers["x-access-token"] = getAcessToken() || "empty";
      // config.headers["authorization"] = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjoxLCJuYW1lIjoicmFuamVldCJ9LCJpYXQiOjE2OTQ1MDQzNzgsImV4cCI6MTY5OTY4ODM3OH0.1um5SlVk7Wl_dlQElFpHQBOSZ8Luu20_eDrK79GylSQ";
      config.headers["authorization"] = getHeaders();
    }

    // console.log("secure payload before encrypt", config.data);
    //encrypt data ----------------
    let temp_data = { bytes: null };
    // if (process.env.ENV_MODE !== "DEV" && isEncryptDecrypt) {
    if (isEncryptDecryptEnabled) {
      temp_data["bytes"] = await secure.encryptData(config.data);
      // console.log("secure payload after encrypt123", temp_data["bytes"]);
      config.data = temp_data;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// responce middleware
axiosInstance.interceptors.response.use(
  async (result) => {
    let res = result;
    // if (result?.data?.status == 2 || result?.data?.status == -1) {
    //   localStorage.removeItem("user");
    //   window.location.replace("/");
    // }

    // console.log("ax responce in axios interceptors before", res);

    // console.log("secure responce before decrypt", res?.data?.data);

    //decrypte data ---------------
    let temp_data = res?.data?.data;

    // console.log("ax responce data in axios interceptors before", temp_data);
    // if (process.env.ENV_MODE !== "DEV" && isEncryptDecrypt) {
    if (isEncryptDecryptEnabled) {
      temp_data = await secure.decrypteData(temp_data);
      res.data.data = await temp_data;
    }
    // ---------------
    // console.log("ax responce in axios interceptors after", res);
    // console.log("secure responce after decrypt", res?.data?.data);

    return res;
  },
  async (err) => {
    // console.log("test1234 err",err)
    // console.log("test1234 pathname",window.location.pathname)

    // if (
    //   err?.response?.data?.status == -1 &&
    //   !public_routes.includes(window.location.pathname)
    // ) {
    //   removeAcessToken()
    //   removeBypassTokens()
    //   signOut()
    //   window.location.replace("/auth/signin");
    // }
    return Promise.reject(err);
  }
);

const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export default axiosInstance;
