import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import userThunkAPI from "@src/store/features/user/middleware";
//import menu component
// import LeftMenuComponent from "../../components/common/LeftMenuComponent";

//import menulist constant
import { MENUS_LEFT_SIDEBAR } from "../../utils/menusLeftSidebar";

//images
import logoBlack from "@src/static/img/logo-black.svg";
// Auth0
import { useUser } from "@auth0/nextjs-auth0/client";

//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["dashboard"])),
    },
  };
}

const Sidebar = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { user, error } = useUser();
  const { t: translate } = useTranslation("dashboard");
  const { userLoginData, userDetails } = useSelector((state) => state.user);
  const attributes = userLoginData?.attributes;
  const [isChild, setIsChild] = useState(false);
  const [userPlan, setUserPlan] = useState(false);
  const [docWidth, setDocWidth] = useState(null);
  const toggleChildClass = () => {
    if (docWidth < 768) {
      setIsChild(!isChild);
      document.body.classList.toggle("overflow-hidden");
    }
  };
  const isActive = (routelink, activeMenuRoutes) => {
    return (
      (activeMenuRoutes && activeMenuRoutes.includes(router.pathname)) ||
      routelink == router.pathname
    );
  };
  useEffect(() => {
    try {
      let tempUserPlan = JSON.parse(localStorage.getItem("userDetails"));
      if (
        tempUserPlan?.subscription_status == "CREATOR" ||
        tempUserPlan?.subscription_status == "BUSINESS"
      ) {
        setUserPlan(true);
      }
    } catch (error) {}
    const updateDocWidth = () => {
      setDocWidth(window.innerWidth);
    };
    updateDocWidth();
    window.addEventListener("resize", updateDocWidth);
    return () => {
      window.removeEventListener("resize", updateDocWidth);
    };
  }, []);
  useEffect(() => {
    if (user?.sub) {
      try {
        dispatch(userThunkAPI.getUserDetailsAsync(user?.sub));
      } catch (error) {}
    }
  }, []);
  return (
    <>
      <div
        className={
          isChild
            ? "block fixed top-0 right-0 bottom-0 left-0 bg-black-color-900 opacity-80 z-10"
            : "hidden"
        }
      ></div>
      <div
        className="block absolute right-3 rtl:right-auto rtl:left-3 top-7 md:hidden cursor-pointer"
        onClick={toggleChildClass}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23 9.66675H1C0.448 9.66675 0 9.21875 0 8.66675C0 8.11475 0.448 7.66675 1 7.66675H23C23.552 7.66675 24 8.11475 24 8.66675C24 9.21875 23.552 9.66675 23 9.66675ZM23 2H1C0.448 2 0 1.552 0 1C0 0.448 0.448 0 1 0H23C23.552 0 24 0.448 24 1C24 1.552 23.552 2 23 2ZM23 17.3333H1C0.448 17.3333 0 16.8853 0 16.3333C0 15.7813 0.448 15.3333 1 15.3333H23C23.552 15.3333 24 15.7813 24 16.3333C24 16.8853 23.552 17.3333 23 17.3333Z"
            fill="#6F7582"
          />
        </svg>
      </div>
      <div
        className={
          !isChild
            ? "fixed top-0 left-0 rtl:left-auto rtl:right-0 w-64 h-screen transition-transform -translate-x-full rtl:translate-x-full md:translate-x-0 rtl:md:-translate-x-0 border-r rtl:border-l rtl:border-r-0 border-gray-200"
            : "fixed top-0 left-0 rtl:left-auto rtl:right-0 z-40 w-64 h-screen transition-transform translate-x-0 rtl:-translate-x-0 md:translate-x-0 rtl:md:-translate-x-0 border-r rtl:border-l rtl:border-r-0 border-gray-200"
        }
        aria-label="Sidebar"
      >
        <div className="h-full px-4 pb-4 pt-6 overflow-y-auto bg-gray-50">
          <div
            className="block absolute right-3 rtl:right-auto rtl:left-3 top-3 md:hidden cursor-pointer"
            onClick={toggleChildClass}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_406_16968)">
                <path
                  d="M18.3 5.70973C17.91 5.31973 17.28 5.31973 16.89 5.70973L12 10.5897L7.10997 5.69973C6.71997 5.30973 6.08997 5.30973 5.69997 5.69973C5.30997 6.08973 5.30997 6.71973 5.69997 7.10973L10.59 11.9997L5.69997 16.8897C5.30997 17.2797 5.30997 17.9097 5.69997 18.2997C6.08997 18.6897 6.71997 18.6897 7.10997 18.2997L12 13.4097L16.89 18.2997C17.28 18.6897 17.91 18.6897 18.3 18.2997C18.69 17.9097 18.69 17.2797 18.3 16.8897L13.41 11.9997L18.3 7.10973C18.68 6.72973 18.68 6.08973 18.3 5.70973Z"
                  fill="#6F7582"
                />
              </g>
              <defs>
                <clipPath id="clip0_406_16968">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <Link className="mb-9 block pl-2.5" href="/dashboard">
            {" "}
            <Image src={logoBlack} alt="" />
          </Link>
          <ul
            className="space-y-2 font-medium mySiteLeftBarUl"
            onClick={toggleChildClass}
          >
            {MENUS_LEFT_SIDEBAR?.map((item, index) => (
              <MenuItem
                key={index}
                menu_name={item?.menu_name}
                status={item?.status}
                icon={item?.icon}
                link={item?.link}
                svgIcon={item?.svgIcon}
                isActive={isActive(item?.link, item?.activeMenuRoutes)}
                target={item?.target}
                name_soon={item?.name_soon}
                userDetails={userDetails}
              />
            ))}
          </ul>

          {!userPlan ? (
            <div className="p-4 rounded-lg bg-white border border-gray-100 mt-4">
              <div className="flex font-semibold text-sm items-center mb-2">
                <svg
                  width="24"
                  height="23"
                  className="mr-2 rtl:mr-0 rtl:ml-2"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 19.0267L17.5333 22.3733C18.5466 22.9867 19.7866 22.08 19.52 20.9333L18.0533 14.64L22.9466 10.4C23.84 9.62667 23.36 8.16 22.1866 8.06667L15.7466 7.52L13.2266 1.57334C12.7733 0.493336 11.2266 0.493336 10.7733 1.57334L8.25331 7.50667L1.81331 8.05334C0.639975 8.14667 0.159975 9.61334 1.05331 10.3867L5.94664 14.6267L4.47998 20.92C4.21331 22.0667 5.45331 22.9733 6.46664 22.36L12 19.0267Z"
                    fill="#38A376"
                  />
                </svg>
                {translate("Upgrade to Pro")}
              </div>
              <div className="sddsf text-black-color-400 mb-4 text-sm">
                {translate(
                  "Get more customization and manage your sites easily."
                )}
              </div>
              <Link
                href="/pricing"
                className="font-semibold text-project-color-500 text-sm"
              >
                {translate("Upgrade plan")}
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const MenuItem = (props) => {
  const [getUsername, setUsername] = useState("");
  const [routerLink, setRouterLink] = useState("");
  const [slug, setSlug] = useState("");
  const { t: translate } = useTranslation("dashboard");
  useEffect(() => {
    if (typeof window !== "undefined") {
      const temp = window.localStorage.getItem("my-current-site");
      setSlug(temp);
      setUsername(temp);
      // check user plan

      const user = localStorage.getItem("userDetails");
      if (!user) {
        return;
      }
      const userDetails = JSON.parse(user);

      let tempRouterLink = props.link;
      switch (props?.menu_name) {
        case "Stylize look":
          if (userDetails?.subscription_status == "FREEPLAN") {
            tempRouterLink = "/pricing";
          }
          break;
        case "Site Analytics":
          if (userDetails?.subscription_status == "FREEPLAN") {
            tempRouterLink = "/pricing";
          }
          break;
        case "Integration":
          if (userDetails?.subscription_status == "FREEPLAN") {
            tempRouterLink = "/pricing";
            // tempRouterLink = "/my-site/integration";
          }
          break;
        case "E-Commerce":
          if (
            userDetails?.subscription_status == "FREEPLAN" ||
            userDetails?.subscription_status == "CREATOR"
          ) {
            tempRouterLink = "/pricing";
            // tempRouterLink = "/my-site/integration";
          }
          break;
        case "Utm builder":
          if (userDetails?.subscription_status == "FREEPLAN") {
            tempRouterLink = "/pricing";
          }
          break;
        case "Get verified":
          if (userDetails?.subscription_status == "FREEPLAN") {
            tempRouterLink = "/pricing";
          }
          break;
        case "Subscribers":
          if (userDetails?.subscription_status == "FREEPLAN") {
            tempRouterLink = "/pricing";
          }
          break;

        default:
          tempRouterLink = props.link;
          break;
      }
      setRouterLink(tempRouterLink);
    }
  }, [slug]);
  return (
    <li
      key={props?.key}
      className={
        translate(props?.menu_name) == "Site settings"
          ? "borderbottomline"
          : null
      }
    >
      <Link
        href={
          routerLink == "/my-site"
            ? `${routerLink}?id=${getUsername}`
            : routerLink
        }
        className={
          props?.isActive
            ? "bg-mor-blue-400 p-2 text-mor-blue-600 rounded-lg flex items-center  group py-2.5 font-semibold"
            : "flex items-center p-2 text-gray-900 rounded-lg group py-2.5 font-semibold"
        }
        target={props?.target == "newpage" ? "_blank" : ""}
      >
        <div dangerouslySetInnerHTML={{ __html: props.svgIcon }} />
        <span className="ml-3 rtl:ml-0 rtl:mr-3">
          {translate(props?.menu_name)}
        </span>

        {(props?.status &&
          props?.userDetails?.subscription_status == "FREEPLAN") ||
        (props?.menu_name == "E-Commerce" &&
          props?.userDetails?.subscription_status !== "BUSINESS") ? (
          <span className="text-sm text-violet-600 bg-purple-100 px-2 rounded-full py-0.5 ml-2 rtl:ml-0 rtl:mr-2">
            {translate(props?.status)}
          </span>
        ) : null}
        <span className="absolute right-5 inline-flex items-center justify-center px-2 ml-3 text-xs font-medium bg-gray-100 rounded-full ">
          {translate(props?.name_soon)}
        </span>
      </Link>
    </li>
  );
};

export default Sidebar;
