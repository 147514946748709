import React, { useEffect, useState } from "react";
import Link from "next/link";
import Head from "next/head";
import { Dropdown } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import Image from "next/image";
import logoIcon from "@src/static/img/logo-black.svg";
import userUpload from "@src/static/img/user-img-2.svg";
import { routesToBackToDashboard } from "../../utils/helper";
import BackToDash from "@src/components/layout/BackToDash";
import { DASH_MENUS_LEFT_SIDEBAR } from "@src/utils/dashMenusLeftSidebar";
import LanguageSwitcher from "@src/components/common/LanguageSwitcher";
// Auth0
import { useUser } from "@auth0/nextjs-auth0/client";

//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["dashboard"])),
    },
  };
}

const Header = (props) => {
  const { user, error } = useUser();
  const { meta_title } = props;
  const dispatch = useDispatch();
  const { t: translate } = useTranslation("dashboard");
  const { userLoginData, userDetails } = useSelector((state) => state.user);
  const attributes = userLoginData?.attributes;
  const router = useRouter();

  const handleLogout = async () => {
    localStorage.setItem("auth0Token", "");
    localStorage.setItem("userDetails", "");
    localStorage.setItem("my-current-site", "");
    router.push("/api/auth/logout");
  };

  useEffect(() => {
    if (user?.sub) {
      try {
        dispatch(userThunkAPI.getUserDetailsAsync(user?.sub));
      } catch (error) {}
    }
  }, [user]);
  let backToDashboard = routesToBackToDashboard.includes(router.pathname);

  const currentPath = router.pathname;

  // Find the matching menu item
  const matchingMenuItem = DASH_MENUS_LEFT_SIDEBAR.find(
    (item) => item.link === currentPath
  );
  return (
    <div>
      <Head>
        {/* Primary Meta Tags */}
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>{meta_title}</title>
        <meta
          name="description"
          content="Single Link, Maximum Reach & Engagement."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" href="/path-to-your-favicon.ico" type="image/x-icon" />

        <link rel="canonical" href="https://mor.link/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mor.link/" />
        <meta
          property="og:title"
          content="Mor.link! - One link to grow your business."
        />
        <meta
          property="og:description"
          content="One link to grow your business."
        />
        <meta
          property="og:image"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />
        <meta
          property="og:image:width"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />
        <meta
          property="og:image:height"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />

        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://mor.link/" />
        <meta
          property="twitter:title"
          content="Mor.link! - One link to grow your business."
        />
        <meta
          property="twitter:description"
          content="One link to grow your business."
        />
        <meta
          property="twitter:image"
          content="https://d21l5byxr8gnpg.cloudfront.net/profiles_avtar/Tag_manager.png"
        />

        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WBCQTZMN"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        {/* intercom */}
        {/* <script dangerouslySetInnerHTML={{ __html: intercom_data}} /> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WBCQTZMN');
          `,
          }}
        />
        {/* intercom */}
      </Head>
      <div className="innerHeaderMain py-3.5 pl-4 md:pl-6 pr-6 flex justify-between border-b border-gray-200">
        <div className="flex items-center">
          <Link
            className="block md:hidden mr-4 rtl:mr-0 rtl:ml-4"
            href="/dashboard"
          >
            <Image src={logoIcon} alt="" />
          </Link>
          <div>
            {matchingMenuItem && (
              <div className="hidden sm:flex items-center font-medium text-sm sm:text-lg">
                {translate(matchingMenuItem.menu_name)}
              </div>
            )}
          </div>
          {!backToDashboard && <BackToDash />}
        </div>

        <div className="innerHeadRightBox flex items-center">
          <LanguageSwitcher />
          {userDetails?.subscription_status == "FREEPLAN" ? (
            <Link
              className="text-xs sm:text-base font-semibold border border-gray-300 rounded-lg inline-block sm:px-4 px-2 py-2 ml-2.5"
              href="/pricing"
            >
              {translate("Upgrade now")}
            </Link>
          ) : null}
          <div className="headProfile ml-3 rtl:mr-3 mr-7 rtl:ml-9 md:mr-0 rtl:md:ml-0">
            <Dropdown
              className="inputMain headDropBox"
              label={
                <Image
                  src={userDetails?.profile_image_url || userUpload} // Display the selected image or a default image
                  alt=""
                  style={{ borderRadius: "50%" }}
                  width={45}
                  height={45}
                />
              }
            >
              <Dropdown.Item className="text-black userNameEmail block text-left rtl:text-right">
                <h2 className="block font-semibold">
                  {`${userDetails?.first_name} ${userDetails?.last_name}` ||
                    null}
                </h2>
                {/* <p className="block">
                  {userDetails?.name || null}
                </p> */}
              </Dropdown.Item>
              <Link href="/profile-setting">
                <Dropdown.Item className="block text-left rtl:text-right">
                  {translate("Profile Settings")}
                </Dropdown.Item>
              </Link>

              <Dropdown.Item className="block text-left rtl:text-right">
                <div onClick={handleLogout}>{translate("Log out")}</div>
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
